import { Link } from "gatsby";
import React from "react";
import { Container } from "react-bootstrap";
import { AiOutlineArrowDown } from "react-icons/ai";
import MainLayout from "../components/MainLayout";
import "../styles/case-studies.scss";

interface CaseStudiesProps {}

const caseStudies = [
  {
    title: "Featherlite",
    description:
      "Featherlite is one of the leading furniture manufacturing organizations in India, providing complete office furniture solutions for all segments of the market through its strong base of direct and franchisee offices present across 45 locations in India.",
    image: "/images/case-studies/feathelite.png",
    path: "/featherlite-case-study"
  },
  {
    title: "Luxury Ride",
    description:
      "Luxury Ride is India's biggest pre-owned, multi-brand luxury car showroom chain. It's your one-stop shop for your luxury car buying, selling, servicing, detailing, insurance, roadside assistance and much more.",
    image: "/images/case-studies/luxuryride.png",
    path: "/luxuryride-case-study"
    
  },
  {
    title: "CARS24",
    description:
      "CARS24 is a leading AutoTech company streamlining and revolutionising the sale, purchase, and financing of pre-owned cars in India, Australia, Thailand, and UAE.",
    image: "/images/case-studies/cars24.png",
    path: "/cars24-case-study"
  },
  {
    title: "Spinny",
    description:
      "Spinny is the most trusted way of buying and selling used cars. It allows you to choose from over 5000 fully inspected second-hand car models and book a test drive at your home or at a Spinny Car Hub near you.",
    image: "/images/case-studies/spinny.png",
    path: "/spinny-case-study"
  },
  {
    title: "Boult",
    description:
      "Boult Audio is a high-end consumer electronics company that designs and manufactures innovative audio products like smart watches and Bluetooth earbuds.",
    image: "/images/case-studies/boult.png",
    path: "/boult-case-study"
  },
  {
    title: "LML",
    description:
      "LML Electric is an iconic automobile brand based in Kanpur, they create vehicles that define style and effortless commuting for users around the globe.",
    image: "/images/case-studies/lml.png",
    path: "/lml-case-study"
  },
];
const CaseStudies = () => {
  return (
    <MainLayout width={100} hideFooter>
      <div
        style={{ flex: "1 1 auto" }}
        className="bg-white pointer-events-all d-flex flex-column"
      >
        <div className="case-studies-wrapper">
          <Container>
            <div className="d-flex justify-content-between mb-5 case-studies-header">
              <h1>Startups to Fortune 500’s</h1>
              <Link to="/contact-us">
                <button className="button-primary">Book Demo Call</button>
              </Link>
            </div>
            <div className="case-studies-list" style={{
              display:"flex",
              flexDirection:"row",
              flexWrap:"wrap",
              justifyContent:"space-evenly"
            }}>
              {caseStudies.map((study) => (
                <Link to={study.path} style={{
                  width:"30%",
                  minWidth:"300px"
                }}>
                  <button className="case-studies-item" style={{
                    minHeight:"400px"
                  }}>
                    <img className="mb-3" src={study.image} alt={study.title} />
                    <h5 className="px-3">{study.title}</h5>
                    <p className="px-3">{study.description}</p>
                  </button>
                </Link>
              ))}
            </div>
          </Container>
        </div>
      </div>
    </MainLayout>
  );
};

export default CaseStudies;
